import React, {useState} from 'react';
import {Alert, Button, Form, Modal} from "react-bootstrap";
import {Formik, Form as FormikForm, Field, FieldArray} from "formik";

import ImageField from "../../components/form/ImageField";
import {getApiErrorMessage} from "../../utils/apiErrors";
import baseApi from "../../apis/baseApi";
import {Coach, CoachPaymentRange} from "./index";
import {FormikSelect} from "../../components/form/FormikSelect";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import {useDebouncedCallback} from "use-debounce";
import FormField2 from '../../components/form/FormField2';
import {useDispatch, useSelector} from "react-redux";
import {addCoach, updateCoach} from "../../actions/coaches";
import Checkbox from "../../components/form/Checkbox";
import {StoreState} from "../../reducers";
import {Branch, BranchesState} from "../../actions";
import FormSelectAlt, {SelectOption} from "../../components/form/FormSelectAlt";


type CreateCoachModalProps = {
    show: boolean
    onHide: Function
    coach?: Coach
    onSave: (coach: Coach) => void
}

const CreateEditCoachModal = ({show, onHide, coach, onSave}: CreateCoachModalProps) => {
    const dispatch = useDispatch()
    const [img, setImg] = useState<File>();
    const [success, setSuccess] = useState<boolean | null>(null);
    const [alertMessage, setAlertMessage] = useState("")
    const branchesState = useSelector<StoreState, BranchesState>(state => state.branches)
    const currentBranch = useSelector<StoreState, Branch>(state => state.currentBranch)
    const [selectedBranchId, setSelectedBranchId] = useState<string | undefined>(currentBranch.id);

    const renderAlert = () => {
        if (success === null) return;

        return <Alert variant={success ? "success" : "danger"}>{alertMessage}</Alert>;
    };

    const handleClose = () => {
        setSuccess(null);
        setSelectedBranchId(currentBranch.id)
        onHide();
    };

    const saveInput = useDebouncedCallback((ranges: CoachPaymentRange[], setFieldValue) => {
        let start = 0
        let end = 0
        ranges.forEach((r, index) => {
            const fieldName = `payment_preferences.ranges[${index}].range_start`
            if (index === 0 && r.range_start !== 0) {
                setFieldValue(fieldName, 0)
            }
            if (index > 0) {
                if (r.range_start !== end + 1) {
                    setFieldValue(fieldName, end + 1)
                }
            }
            start = r.range_start
            end = r.range_end
        })
    }, 350)

    return (
        <React.Fragment>
            <Modal size="lg" show={show} onHide={handleClose}>
                <Formik initialValues={{
                    full_name: coach?.full_name,
                    branch: coach?.payment_preferences_branch.find(p => p.branch === currentBranch.id)?.branch,
                    payment_preferences: coach?.payment_preferences_branch.find(p => p.branch === selectedBranchId) ?? coach?.payment_preferences,
                }}
                        onSubmit={(values, {setSubmitting, setFieldValue}) => {
                            setSuccess(null)
                            let data: any = values
                            if (values.branch) {
                                data.payment_preferences_branch = [{
                                    ...values.payment_preferences,
                                    branch: values.branch
                                }]
                                delete data.payment_preferences
                            } else {
                                if (data.payment_preferences) {
                                    data.payment_preferences.branch = null
                                    data.payment_preferences.coach_multi = null
                                    delete data.payment_preferences_branch
                                }
                            }
                            if (coach) { // UPDATE COACH
                                baseApi.patch<Coach>(`/coaches/update/${coach.id}/`, data).then((resp) => {
                                    setSuccess(true)
                                    dispatch(updateCoach(resp.data))
                                    if (img && coach.id) {
                                        const formData = new FormData();
                                        formData.append("img", img, img.name);
                                        baseApi.patch(`/coaches/update/${coach.id}/`, formData).then((resp) => {
                                                dispatch(updateCoach(resp.data))
                                                setSubmitting(false)
                                            }
                                        )
                                    }
                                    setAlertMessage("Se ha actualizado el coach correctamente.")
                                    setSubmitting(false)
                                    setFieldValue("payment_preferences", resp.data.payment_preferences_branch.find(p => p.branch === selectedBranchId) ?? resp.data.payment_preferences)
                                    onSave(resp.data)
                                }).catch((err) => {
                                    setAlertMessage(getApiErrorMessage(err))
                                    setSuccess(false)
                                    setSubmitting(false)
                                })
                            } else { // CREATE
                                baseApi.post<Coach>('/coaches/', data).then((resp) => {
                                    if (img && resp.data.id) {
                                        const formData = new FormData();
                                        formData.append("img", img, img.name);
                                        baseApi.patch(`/coaches/update/${resp.data.id}/`, formData).then((resp) => {
                                                dispatch(addCoach(resp.data))
                                            }
                                        )
                                    } else {
                                        dispatch(addCoach(resp.data))
                                    }
                                    setSubmitting(false)
                                    setSuccess(true)
                                    setAlertMessage("Se ha creado el coach correctamente.")
                                    setFieldValue("payment_preferences", resp.data.payment_preferences_branch.find(p => p.branch === selectedBranchId) ?? resp.data.payment_preferences)
                                    onSave(resp.data)
                                }).catch((err) => {
                                    setAlertMessage(getApiErrorMessage(err))
                                    setSuccess(false)
                                    setSubmitting(false)
                                })
                            }
                        }}>
                    {({isSubmitting, setFieldValue, values}) => (
                        <FormikForm>
                            <Modal.Header>
                                {coach ? "Editar" : "Crear"} Coach
                            </Modal.Header>
                            <Modal.Body>
                                {renderAlert()}
                                <h3 className="text-muted font-size-lg">Nombre coach</h3>
                                <FormField2 name="full_name" label=""/>
                                <div className="">
                                    <h3 className="text-muted pt-2 font-size-lg">Imagen</h3>
                                    <ImageField src={coach?.img} onChange={(file) => setImg(file)} quality={100}/>
                                </div>
                                <hr/>

                                {/* INICIO COMISIONES */}
                                <div className=''>
                                    <h3 className="text-left mt-5 label label-inline label-light-primary label-lg font-size-h3 p-5 rounded-xl">💵
                                        Comisiones</h3>
                                    <div className='mb-5 text-dark-50 ml-2'>Calcula el pago de tus coaches
                                        automáticamente por numero de reservas o asistencias en cada clase que impartan.
                                    </div>
                                    <div className="">
                                        <h3 className="text-dark font-size-lg">Selecciona cómo se calculará el pago de
                                            tu coach</h3>
                                        <div hidden={branchesState.branches.length === 1}>
                                            <FormSelectAlt
                                                label="Sucursal" placeholder="Seleccionar sucursal"
                                                value={branchesState.selectOptions.find(b => b.value === values.branch)}
                                                options={[{
                                                    value: undefined,
                                                    label: "Todas"
                                                }, ...branchesState.selectOptions]}
                                                onChange={(option: SelectOption) => {
                                                    setSelectedBranchId(option.value)
                                                    setFieldValue("branch", option.value);
                                                    if (coach) {
                                                        let preference
                                                        if (!option.value) {
                                                             preference = coach.payment_preferences
                                                        } else {
                                                             preference = coach.payment_preferences_branch?.find(p => p.branch === option.value)
                                                        }
                                                        if (preference) {
                                                            setFieldValue("payment_preferences.payment_type", preference.payment_type)
                                                            setFieldValue("payment_preferences.count_cancelled_rsvp_outside_limit", preference.count_cancelled_rsvp_outside_limit)
                                                            setFieldValue("payment_preferences.ranges", [])
                                                            preference.ranges.forEach((r, index) => {
                                                                setFieldValue(`payment_preferences.ranges[${index}].amount`, r.amount)
                                                                setFieldValue(`payment_preferences.ranges[${index}].range_start`, r.range_start)
                                                                setFieldValue(`payment_preferences.ranges[${index}].range_end`, r.range_end)
                                                            })
                                                        }
                                                    }
                                                }}
                                                offset="0"
                                            />
                                        </div>
                                        <div className="col-lg-6">
                                            <Field name="payment_preferences.payment_type"
                                                   component={FormikSelect}
                                                   options={[
                                                       {value: undefined, label: "No calcular"},
                                                       {value: 0, label: "Por # de asistencias por clase"},
                                                       {value: 1, label: "Por # de reservaciones por clase"},
                                                   ]}
                                                   onChange={(val: any) => {
                                                       if (val.value !== undefined) {
                                                           const range1 = {
                                                               id: "",
                                                               range_start: 0,
                                                               range_end: 1,
                                                               amount: 0,
                                                           }
                                                           setFieldValue("payment_preferences.ranges", [range1])
                                                       } else {
                                                           setFieldValue("payment_preferences", null)
                                                       }
                                                   }}
                                            />
                                        </div>
                                        {/*<hr />*/}
                                        <div className="mt-2 ml-3">
                                            <Field component={Checkbox} type="checkbox"
                                                   name="payment_preferences.count_cancelled_rsvp_outside_limit"
                                                   label="Contar cancelaciones sin devolución de credito"/>
                                        </div>
                                        <hr/>
                                        <Form.Group className="row">
                                            <div className=' rounded p-5 mt-1'>
                                                <FieldArray
                                                    name="payment_preferences.ranges"
                                                    render={arrayHelpers => (
                                                        <div>
                                                            {values.payment_preferences?.ranges?.map((range, index) => (
                                                                    <div key={index} className="d-flex mb-2">
                                                                        <div
                                                                            className=" align-self-center font-size-sm mr-3 label label-inline label-light-dark label-lg font-weight-bolder rounded-xl">Rango {index + 1}</div>
                                                                        <div className="mx-3">
                                                                            <div className="input-group ">
                                                                                <div
                                                                                    className="input-group-prepend font-size-xs">
                                                                                    <span
                                                                                        className="input-group-text text-muted font-size-sm">$</span>
                                                                                </div>

                                                                                <Field
                                                                                    name={`payment_preferences.ranges[${index}].amount`}
                                                                                    type="number"
                                                                                    className="form-control w-75px"/>
                                                                                <div
                                                                                    className="input-group-append font-size-xs">
                                                                                    <span
                                                                                        className="input-group-text text-muted font-size-sm">por clase</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className='align-self-center'>
                                                                            si obtiene de <span
                                                                            className='font-size-lg'>{values.payment_preferences?.ranges[index].range_start}</span> a
                                                                        </div>

                                                                        <div className="mx-3">
                                                                            <div className='input-group'>
                                                                                {/* <div className="input-group-prepend font-size-xs">
                                                                                    <span className="input-group-text text-muted font-size-sm">{values.payment_preferences?.ranges[index].range_start} -</span>
                                                                                </div> */}
                                                                                <Field
                                                                                    name={`payment_preferences.ranges[${index}].range_end`}
                                                                                    type="number"
                                                                                    className="form-control w-75px"
                                                                                    onKeyUp={(e: any) => {
                                                                                        saveInput(values.payment_preferences!.ranges, setFieldValue)
                                                                                    }}
                                                                                />
                                                                                <div
                                                                                    className="input-group-append font-size-xs">
                                                                                    <span
                                                                                        className="input-group-text text-muted font-size-sm">{values.payment_preferences?.payment_type === 0 ? 'asistencias' : 'reservaciones'} en clase</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className='align-self-center'>
                                                                            <Button
                                                                                disabled={values.payment_preferences?.ranges.length === 1}
                                                                                onClick={() => {
                                                                                    arrayHelpers.remove(index);
                                                                                    let ranges = values.payment_preferences!.ranges
                                                                                    ranges.splice(index, 1)
                                                                                    saveInput(ranges, setFieldValue)
                                                                                }}
                                                                                className="btn btn-icon btn-light-danger btn-xs btn-hover-danger mr-1">
                                                                                <FontAwesomeIcon icon={faTimes} size="sm"/>
                                                                            </Button>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            )}
                                                        </div>
                                                    )}
                                                />
                                            </div>
                                        </Form.Group>
                                        <div className='text-center mt-n7'>
                                            <button
                                                hidden={!values.payment_preferences?.ranges}
                                                className="btn btn-link"
                                                type="button"
                                                onClick={() => {
                                                    const ranges = values.payment_preferences!.ranges
                                                    const last = ranges[ranges.length - 1]
                                                    console.log(ranges)
                                                    setFieldValue("payment_preferences.ranges", [...ranges, {
                                                        id: "",
                                                        range_start: last.range_end + 1,
                                                        range_end: last.range_end + 1,
                                                        amount: 0
                                                    },])
                                                }}
                                            >
                                                + agregar otro rango
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                {/* FIN COMISIONES */}
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="outline-secondary" onClick={handleClose}>
                                    Cerrar
                                </Button>
                                <Button type="submit" disabled={isSubmitting}>
                                    {isSubmitting ? "..." : "Confirmar"}
                                </Button>
                            </Modal.Footer>
                        </FormikForm>
                    )}
                </Formik>
            </Modal>
        </React.Fragment>
    );
};

export default CreateEditCoachModal;
