import React from 'react';

const PermissionDenied = () => {
    return (
        <div>
            <h1 className="text-danger">No cuentas con el permiso para ver esta sección.</h1>
        </div>
    );
};

export default PermissionDenied;
